import React from "react"

import logo from "../img/logo-green.png"
import cartIcon from "../img/cart-icon.svg"
import "./header.scss"

const Header = () => (
  <header>
    <div className="content">
      {/* <div className="menu">
        <div className="bar1"></div>
        <div className="bar2"></div>
        <div className="bar3"></div>
      </div> */}
      <div>
        <img src={logo} alt="LR logo" />
      </div>
      <a href="https://shop.lrworld.com/home/dk/da?PHP=eH9LTVp83HvX45%2BCljs4rw%3D%3D" target="_blank" rel="noreferrer">
        <div className="e-shop">
          <img src={cartIcon} alt="cart-icon"/>
          <h4>e-shop</h4>
        </div>
      </a>
    </div>
  </header>
)

export default Header
